<template>
  <div
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div>
      <div :rail="rail" permanent @click="rail = false" class="accueil-drawer">
        <Accueil></Accueil>
      </div>
      <div
        style="margin-top: 20px; margin-right: 5%; margin-left: 5%"
        class="d-flex flex-column align-center justify-center"
      >
        <!--<v-img :src="icon" :width="iconWidth" class="mx-auto mb-5"></v-img>
        <h1 class="text-center" :style="styleTitle">{{ agenceName }}</h1>-->

        <h2
          class="text-center mb-4"
          :style="{
            styleTitle,
            fontFamily: 'Georama',
            fontSize: 24,
            fontWeight: 700,
            marginTop: '150px',
          }"
        >
          Code ePhoto
        </h2>
        <v-alert type="error" v-if="errors.length">
          <ul>
            <li v-for="error in errors" :key="error.id">{{ error }}</li>
          </ul>
        </v-alert>
        <v-text-field
          label="Email*"
          v-model="email"
          outlined
          solo
          class="rounded-bottom"
          :style="{ borderColor: isClicked ? '#47D881' : '' }"
          @focus="handleFocus"
          @blur="handleBlur"
          ><template v-slot:prepend-inner>
            <v-img
              src="../../assets/images/VectorEP.png"
              class="ma-2 photo-icon"
            ></v-img>
          </template>
          <template v-slot:label>
            <span class="text-align-left" style="color: #c1c1c1">Email*</span>
          </template>
        </v-text-field>
        <v-text-field
          label="Confirmez l'email*"
          v-model="confEmail"
          outlined
          solo
          class="rounded-bottom"
          :style="{ borderColor: isClicked ? '#47D881' : '' }"
          @focus="handleFocus"
          @blur="handleBlur"
        >
          <template v-slot:prepend-inner>
            <v-img
              src="../../assets/images/VectorEP.png"
              class="ma-2 photo-icon"
            ></v-img>
          </template>
          <template v-slot:label>
            <span class="text-align-left" style="color: #c1c1c1"
              >Confirmez l'email*</span
            >
          </template>
        </v-text-field>
        <v-text-field
          label="Prénom*"
          v-model="firstName"
          outlined
          solo
          class="rounded-bottom"
          :style="{ borderColor: isClicked ? '#47D881' : '' }"
          @focus="handleFocus"
          @blur="handleBlur"
        >
          <template v-slot:prepend-inner>
            <v-img
              src="../../assets/images/Group _35.png"
              width="15"
              height="16"
              class="ma-2 photo-icon"
            ></v-img>
          </template>
          <template v-slot:label>
            <span class="text-align-left" style="color: #c1c1c1">Prénom*</span>
          </template>
        </v-text-field>
        <v-text-field
          label="Nom*"
          v-model="lastName"
          outlined
          solo
          class="rounded-bottom"
          :style="{ borderColor: isClicked ? '#47D881' : '' }"
          @focus="handleFocus"
          @blur="handleBlur"
        >
          <template v-slot:prepend-inner>
            <v-img
              src="../../assets/images/Group _35.png"
              width="15"
              height="16"
              class="ma-2 photo-icon"
            ></v-img>
          </template>
          <template v-slot:label>
            <span class="text-align-left" style="color: #c1c1c1">Nom*</span>
          </template>
        </v-text-field>
        <v-text-field
          label="Identifiant interne"
          v-model="externalId"
          outlined
          solo
          class="rounded-bottom"
          :style="{ borderColor: isClicked ? '#47D881' : '' }"
          @focus="handleFocus"
          @blur="handleBlur"
        >
          <template v-slot:prepend-inner>
            <v-img
              src="../../assets/images/Group _35.png"
              width="15"
              height="16"
              class="ma-1"
            ></v-img>
          </template>
          <template v-slot:label>
            <span class="text-align-left" style="color: #c1c1c1"
              >Identifiant interne</span
            >
          </template>
        </v-text-field>
        <!-- <div width="300" class="mx-auto">
              <div class="d-flex flex-column">
                <v-btn
                  class="rounded-pill mb-5 mt-5"
                  width="240px"
                  height="52px"
                  @click="startPhotoShoot"
                  :color="color1"
                  :style="{
                    color: buttonTextValidColor,
                    fontWeight: 600,
                    fontSize: 24,
                    fontFamily: 'Georama',
                    textTransform: 'none',
                    'margin-left': '30%',
                  }"
                  >Prendre la photo
                </v-btn>
              </div>
            </div> -->

        <v-btn
          class="rounded-pill mb-5 mt-5"
          width="640px"
          height="52px"
          @click="askUserToTakePhoto"
          :color="color1"
          :style="{
            color: buttonTextValidColor,
            fontWeight: 600,
            fontSize: 24,
            fontFamily: 'Georama',
            textTransform: 'none',
          }"
          >Envoyer un email au client avec un qrCode pour prendre la
          photo</v-btn
        >

        <!--  <v-sheet class="d-flex justify-center mt-5">
        <v-btn
          @click="goBackAction()"
          class="text-none mx-5"
          color="grey-lighten-3"
        >
          Retour
        </v-btn>
        
      </v-sheet>
            -->
      </div>
    </div>
  </div>
</template>
<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
import Accueil from './Accueil.vue'

export default {
  name: 'Payment',
  components: {
    Accueil,
  },
  data() {
    return {
      confEmail: '',
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      errors: [],
      agenceName: '',
      externalId: '',

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      isClicked: false,
    }
  },
  methods: {
    checkForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.errors = []
      const isValidEmail = emailRegex.test(this.email)
      if (!this.email) {
        this.errors.push('Saisissez votre email')
      } else if (!isValidEmail) {
        this.errors.push("Cette adresses e-mail n'est pas valide")
      } else if (!this.confEmail || this.email !== this.confEmail) {
        this.errors.push('Confirmez votre email')
      } else if (!this.firstName) {
        this.errors.push('Le prénom est obligatoire')
      } else if (!this.lastName) {
        this.errors.push('Le nom est obligatoire')
      } else {
        return true
      }
      return false
    },
    goBackAction() {
      this.$router.back()
      document.activeElement.blur()
    },
    startPhotoShoot() {
      if (!this.checkForm()) {
        return
      }
      const partnerAdminUid = this.$route.params.partner_uid
      const partnerId = this.$route.params.agence_id
      const partnerUid = this.$route.params.agence_uid
      requestService
        .post(`/service/auth-qrcode-request-for-student`, {
          userEmail: this.email,
          lastName: this.lastName,
          firstName: this.firstName,
          partnerAdminUid: partnerAdminUid,
          partnerId: partnerId,
          partnerUid: partnerUid,
          externalId: this.externalId,
        })
        .then(response => {
          this.$router.push({
            //name: 'Authentication',
            name: 'Welcome',
            params: { id: response.data.uid },
          })
        })
    },
    askUserToTakePhoto() {
      if (!this.checkForm()) {
        return
      }
      requestService
        .post(`/service/authentication_qrcode_request`, {
          user_email: this.email,
          partner_email: localStorageService.getValue('partnerEmail'),
          lastname: this.lastName,
          firstname: this.firstName,
          external_id: this.externalId,
          partner_admin_uuid: this.$route.params.partner_uid,
        })
        .then(() => {
          this.$store.dispatch(
            'alert/success',
            'La demande de prise de photo a été envoyée à ' + this.email,
          )
          this.confEmail = ''
          this.email = ''
          this.firstName = ''
          this.lastName = ''
          this.address = ''
          this.errors = []
          this.agenceName = ''
          this.externalId = ''
        })
        .catch(() => {
          this.$store.dispatch(
            'alert/error',
            "Impossible d'informer le client.",
          )
        })
    },
    handleFocus() {
      this.isClicked = true
      //console.log(this.isClicked);
    },
    handleBlur() {
      this.isClicked = false
      //console.log(this.isClicked);
    },
  },
  mounted() {
    const uuid = localStorageService.getValue('partner_uid')
    if (
      uuid !== undefined &&
      uuid !== null &&
      !localStorageService.getValue('logo')
    ) {
      requestService.get('/partner/uuid/' + uuid).then(response => {
        if (response.data.success) {
          this.agenceName = response.data.name
          if (response.data.logo !== null) this.icon = response.data.logo
          localStorageService.setObject(
            'logo',
            response.data.logo !== null ? response.data.logo : undefined,
          )
          localStorageService.setObject(
            'primaryColor',
            response.data.primaryColor !== null
              ? response.data.primaryColor
              : undefined,
          )
          localStorageService.setObject(
            'secondaryColor',
            response.data.secondaryColor !== null
              ? response.data.secondaryColor
              : undefined,
          )
        }
      })
    }
  },
}
</script>
<style>
.rounded-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 0px;
  width: 70%;
  background-color: transparent;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.photo-icon {
  margin: auto;
  width: 17px;
  height: 12px;
}
.text-align-left {
  display: inline-block;
  vertical-align: middle;
}
.background-container {
  display: flex;
  flex-direction: column;
}
</style>
